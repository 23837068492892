.backgroundvideoPowered{
    width: 100%;
    height: 40vh;
    position: relative;
    object-fit: cover;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .cardSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 45px;
    margin-top: -130px;
    background-color: #132F4D !important;
  }
  .poweredimg{
    margin: 30px 0px 0px 25px;
  }
  .poweredbyContent{
    font-family: 'Poppins' !important;
    font-size: 42px !important;
    color: #F3F9FF !important;
    line-height: 51.88px !important;
    text-align: center !important;
  }
  
  /* Media query for mobile devices */
@media (max-width: 640px) {
  .cardSection{
    display: flex;
    flex-direction: column;
    padding: 34px;
    margin-top: 114px;
  }
  .poweredbyContent{
    font-size: 40px !important;
    line-height: 51.88px !important;
  }
  .poweredimg{
    width: 431px;
    margin: 10px 0px 0px 30px !important;
  }
  }
@media (max-width: 480px) {
  .cardSection{
    display: flex;
    flex-direction: column;
    padding: 32px;
    margin-top: 102px;
  }
  .poweredbyContent{
    font-size: 32px !important;
    line-height: 51.88px !important;
  }
  .poweredimg{
    width: 327px;
    margin: 0px 0px 0px 15px;
  }
}
@media (max-width: 320px) {
  .cardSection{
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-top: -91px;
  }
  .cardSection{
    display: flex;
    flex-direction: column;
  }
  .poweredimg{
    width: 216px;
    margin: 0px 0px 0px 25px;
  }
  .poweredbyContent{
    font-size: 27px !important;
    line-height: 51.88px !important;
  }
}