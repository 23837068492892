

.mainProgressStepsContainer{
    padding: 50px 0px 200px;
    width: 100%;
    background-color: #BFC8Df;
}

.stepHeading{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 42px;
    color: #020D1F;
    text-align: center;
}

.stepHeading>span{
    color: #0485E1;
}

.events li { 
    display: flex; 
    color: #999;
    justify-content: center;
  }
  
  .events div { 
    position: relative;
    padding: 0 1.5em;  
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.MainHeading{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 36px;
    color: #020D1F;
}

  .events h3 { 
    position: relative;
    padding: 0 1.5em;  
}

  .events img { 
    position: relative;
    padding: 0 1.5em;  
}
  .events span:hover + div::after {
    display: none;
  }
  .events div:hover + span::after {
    display: none;
  }
  .events div::after { 
     content: "";
     position: absolute;
     z-index: 2;
     right: 0;
     top: 50%;
     transform: translateX(50%);
     border-radius: 50%;
     background: #020D1F;
     border: none;
     width: .8em;
     height: .8em;
     box-shadow: 0px 0px 10px 2px #020D1F;
  }
  .events span::after { 
    content: "";
    position: absolute;
    z-index: 2;
    left: -12px;
    top: 50%;
    transform: translateX(50%);
    border-radius: 50%;
    background: #020D1F;
    border: none;
    width: .8em;
    height: .8em;
    box-shadow: 0px 0px 10px 2px #020D1F;
 }

  .events div:hover::after { 
     background: #FFFFFF;
     box-shadow: 0px 0px 10px 2px #FFFFFF;
  }
  .events span:hover::after { 
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 2px #FFFFFF;
  }
  

  
  .leftDivText{
    text-align: right;
  }

  .progressStepText{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #020D1F;
  }

  .progressStepText:hover{
    color: #fff;
  }
  
  .events span {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
  }
  
  .events span::before {
     content: "";
     position: absolute;
     z-index: 1;
     left: 0;
     height: 100%;
     border-left: 2px #020D1F dotted;
  }
  
  .events strong {
     display: block;
     font-weight: bolder;
  }
  
  .events { margin: 1em auto; width: 65%; }
  .events, 
  .events *::before, 
  .events *::after { box-sizing: border-box; font-family: arial; }

  .progressImage{
    width: 70px;
    height: 70px;
  }
  .mobileProgressStepsContainer{
    display: none;
  }
  @media (max-width: 768px) {
    .mainProgressStepsContainer{
      display: none;
    }
      .mobileProgressStepsContainer{
        display: inline;
        padding: 50px 0px 200px;
        width: 100%;
        background-color: #BFC8Df;
      }
      .stepHeading{
        font-size: 32px;
        text-align: left;
        width: 311px;
        line-height: 39.53px;
        margin-left: 50px;
      }
      .MobileHeading{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 36px;
        color: #020D1F;
      }
      .events li { 
        justify-content: left;
      }
      .events span:hover::after { 
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 2px #FFFFFF;
      }
      .events h3 { 
        padding: 0px !important;  
      }
      .events {
        margin: 1em 22px;
        width: 65%;
      }
      .leftDivText{
        text-align: left;
      }
      .events span::after { 
        content: "";
        position: absolute;
        z-index: 1;
        left: -4px !important;
        top: 50%;
        transform: translateX(50%);
        border-radius: 50%;
        background: #020D1F;
        border: none;
        width: .3em;
        height: .3em;
        box-shadow: 0px 0px 10px 2px #020D1F;
      }
      .events span:hover::after { 
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 2px #FFFFFF;
      }
  }
  @media (max-width: 640px) {
      .mainProgressStepsContainer{
        display: none;
      }
        .mobileProgressStepsContainer{
          display: inline;
          padding: 50px 0px 200px;
          width: 100%;
          background-color: #BFC8Df;
        }
        .stepHeading{
          font-size: 32px;
          text-align: left;
          width: 311px;
          line-height: 39.53px;
          margin-left: 50px;
        }
        .MobileHeading{
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
          font-size: 36px;
          color: #020D1F;
        }
        .events li { 
          justify-content: left;
        }
        .events span:hover::after { 
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 2px #FFFFFF;
        }
        .events h3 { 
          padding: 0px !important;  
        }
        .events {
          margin: 1em 22px;
          width: 65%;
        }
        .leftDivText{
          text-align: left;
        }
        .events span::after { 
          content: "";
          position: absolute;
          z-index: 1;
          left: -4px !important;
          top: 50%;
          transform: translateX(50%);
          border-radius: 50%;
          background: #020D1F;
          border: none;
          width: .3em;
          height: .3em;
          box-shadow: 0px 0px 10px 2px #020D1F;
        }
        .events span:hover::after { 
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 2px #FFFFFF;
      }
  }