.whiteContent{
    color:#F3F9FF;
}
.blueContent{
    color:#0485E1;
}
.mainHead{
  display: inline-block;
  width: 950px !important;
  text-align: center !important;
  font-size: 45px !important;
  line-height: 117% !important;
  text-transform: lowercase;
  font-weight: 600;
  font-family: 'Poppins';

}
.YourmainHead1{
  color: var(--BFC8DF, #BFC8DF);
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.023%; /* 24.005px */
}
.YourButton{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 5px;
  background: var(--F3F9FF, #F3F9FF);
}
.Yourimg1{
  width: 609px;
}
.YourContent{
  display: flex;
  justify-content: center;
  margin-top: 173px;
}
.YourContentButton{
  text-align: center;
  margin: 25px 188px 0px 0px !important;
}

/* Media query for mobile devices */
@media (max-width: 640px) {
  
  .mainHead{
    width: 335px !important;
    text-align: left !important;
  }
  .Yourimg1{
    width: 341px;
  }
  .YourContent{
    margin-top: 20px;
  }
  .YourContentButton{
    text-align: left;
    margin: 13px 0px 0px 55px !important;
  }
  .YourButton{
    padding: 10px 12px;
  }
  .Grid4{
    order:1;
  }
  .Grid3{
    order:2;
  }
}
@media (max-width: 480px) {

  .mainHead{
    width: 335px !important;
    text-align: left !important;
  }
  .Yourimg1{
    width: 259px;
  }
  .YourContent{
    margin-top: 15px;
  }
  .YourContentButton{
    text-align: left;
    margin: 13px 0px 0px 0px !important;
  }
  .YourButton{
    padding: 10px 12px;
  }
  .Grid4{
    order:1;
  }
  .Grid3{
    order:2;
  }

}
@media (max-width: 320px) {

  .mainHead{
    width: 209px !important;
    text-align: left !important;
    font-size: 25px !important;
    text-align: center !important;
  }
  .Yourimg1{
    width: 187px;
  }
  .YourContent{
    margin-top: 15px;
  }
  .YourContentButton{
    text-align: left;
    margin: 13px 0px 0px 0px;
  }
  .YourButton{
    padding: 10px 12px;
  }
  .Grid4{
    order:1;
  }
  .Grid3{
    order:2;
  }

}