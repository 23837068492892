@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

/* * {
  box-sizing: border-box;
} */


.ListMainContainer {
  width: 100%;
  min-height: 1050px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
  background-color: #bfc8df;
}

.ListHeading{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 42px;
  color: #020D1F;
  text-align: center;
  margin: 0px 10px;
}

.ListDescription{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #020D1F;
  text-align: center;
  margin-bottom: 40px;
}

.ListHeading>span{
  color: #0485E1;
}

input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 60%;
  /* height: 500px; */
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 800px;
  max-height: 800px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.CardImage {
  width: 546px;
  height: 435.06px;
  border-radius: 10px;
  object-fit: cover;
}

#item-1:checked ~ .cards #song-3, #item-2:checked ~ .cards #song-1, #item-3:checked ~ .cards #song-2 {
  transform: translatex(-45%) scale(.8);
  z-index: 0;
}


#item-1:checked ~ .cards #song-2, #item-2:checked ~ .cards #song-3, #item-3:checked ~ .cards #song-1 {
  transform: translatex(45%) scale(.8);
  z-index: 0;
}

#item-1:checked ~ .cards #song-1, #item-2:checked ~ .cards #song-2, #item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}

.player {
  background-color: #fff;
  border-radius: 8px;
  min-width: 320px;
  padding: 16px 10px;
}

.upper-part {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 36px;
  overflow: hidden;
}

.play-icon{ margin-right: 10px; }

.song-info {
  width: calc(100% - 32px);
  display: block;
}

.song-info .title {
  color: #403d40;
  font-size: 14px;
  line-height: 24px;
}

.sub-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subtitle, .time {
  font-size: 12px;
  line-height: 16px;
  color: #c6c5c6;
}

.time {
  font-size: 12px;
  line-height: 16px;
  color: #a5a5a5;
  font-weight: 500;
  margin-left: auto;
}

.progress-bar {
  height: 3px;
  width: 100%;
  background-color: #e9efff;
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  display: block;
  position: relative;
  width: 60%;
  height: 100%;
  background-color: #2992dc;
  border-radius: 6px;
}

.info-area {
  width: 100%;
  position: absolute;
  top: 0;
  left: 30px;
  transition: transform .4s ease-in;
}

/* #item-2:checked ~ .player #test {
  transform: translateY(0);
}

#item-2:checked ~ .player #test  {
  transform: translateY(-40px);
}

#item-3:checked ~ .player #test  {
  transform: translateY(-80px);
} */


.ownerDetailSection{
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ownnerHeading{
  text-align: center;
  color: #020D1F;
  font-size: 32px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.ownnerDescription{
  text-align: center;
  width: 70%;
  margin: 0 auto;
  color: #111c2c;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .ListHeading{
    font-size: 32px;
  }
  .ListDescription{
    font-size: 16px;
    text-align: center;
    width: 253px;
  }
  .CardImage {
    width: 324px;
    height: 268px;
  }
  .ownnerHeading{
    font-size: 24px;
  }
  .ownnerDescription{
    width: 345px;
    font-size: 18px;
  }
  .ListMainContainer{
    min-height: 830px;
  }
}
@media (max-width: 640px) {
  .ListHeading{
    font-size: 32px;
  }
  .ListDescription{
    font-size: 16px;
    text-align: center;
    width: 253px;
  }
  .CardImage {
    width: 324px;
    height: 268px;
  }
  .ownnerHeading{
    font-size: 22px;
  }
  .ownnerDescription{
    width: 345px;
    font-size: 16px;
  }
  .ListMainContainer{
    min-height: 820px;
  }
}
@media (max-width: 480px) {
  .ListHeading{
    font-size: 32px;
  }
  .ListDescription{
    font-size: 16px;
    text-align: center;
    width: 253px;
  }
  .CardImage {
    width: 224px;
    height: 168px;
  }
  .ownnerHeading{
    font-size: 18px;
  }
  .ownnerDescription{
    width: 325px;
    font-size: 14px;
  }
  .ListMainContainer{
    min-height: 730px;
  }
}