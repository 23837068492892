@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

.whiteContent{
    color:#F3F9FF;
}
.blueContent{
    color:#0485E1;
}
.blueContent1{
    color:#0485E1;
}
.mainHeadHover{
  display: inline-block;
  width: 1000px !important;
  text-align: center !important;
  font-size: 45px !important;
  line-height: 117% !important;
  text-transform: lowercase !important;
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
}
.mainHead1{
    font-family: 'Poppins';
    text-transform: lowercase;
    text-align: center;
    font-size: 18px;
    color:#BFC8DF;
    /* margin-top:30px; */
    width:650px;
}
.hoverImg{
    width: 366px;
    height: 422px;
}
.left{
    border-radius: 42px 0px 0px 42px;
}
.right{
    border-radius: 0px 42px 42px 0px;
}

.imageContent{
    color: #0F2C4A;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 123.523%;
    margin-top: -46px;
    text-align: center;
}
.buttonHoversection{
    display: inline-flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #FFF;
}
.contentButton{
    color: var(--020D1F, #020D1F);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.523%; /* 29.645px */
    text-transform: capitalize;
}


/* body {
  color: #555;
  background: #222;
  text-align: center;
  font-family: 'Roboto Mono';
  padding: 1em;
} */

/* h1 {
  font-size: 2.2em;
} */

.flip {
    position: relative;
    >.front,
    >.back {
        display: block;
        /* transition-timing-function: cubic-bezier(.175, .885, .32, 1.275); */
        transition-duration: 2s;
        transition-property: transform, opacity;
    }
    >.front {
        transform: rotateX(0deg);
    }
    >.back {
        position: absolute;
        opacity: 0;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transform: rotateX(-10deg);
    }
    &:hover {
        >.front {
            transform: rotateX(10deg);
        }
        >.back {
            opacity: 1;
            transform: rotateX(0deg);
        }
    }
}

.flip {
    position: relative;
    display: inline-block;
    >.back {
      display: block;
      color: white;
      width: inherit;
      background-size: cover!important;
      background-position: center!important;
      width :311px;
      height: 395px;
      padding: 1em 2em;
      /* background: #313131; */
      border-radius: 5px;
      p {
        font-size: 0.9125rem;
        line-height: 160%;
        color: #999;
      }
    }
}

@media (max-width: 900px){
    .left{
        border-radius: 0px 0px 0px 0px;
    }
    .right{
        border-radius: 0px 0px 0px 0px;
    }
}

@media (max-width: 768px) {
    .mainHeadHover{
        width: 290px !important;
        text-align: center !important;
        font-size: 32px !important;
        line-height: 39.53px;
        text-transform: capitalize !important;
    }
    .mainHead1{
        text-transform: capitalize;
        text-align: center;
        font-size: 16px;
        width:316px;
        margin-top: 20px !important;
    }
    .hoverImg{
        width: 366px;
        height: 422px;
    }
    .flip {
        position: relative;
        >.front,
        >.back {
            display: block;
            /* transition-timing-function: cubic-bezier(.175, .885, .32, 1.275); */
            transition-duration: 2s;
            transition-property: transform, opacity;
        }
        >.front {
            transform: rotateX(0deg);
        }
        >.back {
            position: absolute;
            opacity: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: rotateX(-10deg);
        }
        &:hover {
            >.front {
                transform: rotateX(10deg);
            }
            >.back {
                opacity: 1;
                transform: rotateX(0deg);
            }
        }
    }
    
    .flip {
        position: relative;
        display: inline-block;
        >.back {
          display: block;
          color: white;
          width: inherit;
          background-size: cover!important;
          background-position: center!important;
          width :311px;
          height: 395px;
          padding: 1em 2em;
          /* background: #313131; */
          border-radius: 5px;
          p {
            font-size: 0.9125rem;
            line-height: 160%;
            color: #999;
          }
        }
    }
    .left{
        border-radius: 0px 0px 0px 0px;
    }
    .right{
        border-radius: 0px 0px 0px 0px;
    }
  }
@media (max-width: 640px) {
    .mainHeadHover{
        width: 250px !important;
        text-align: center !important;
        font-size: 32px !important;
        line-height: 39.53px;
        text-transform: capitalize !important;
    }
    .mainHead1{
        text-transform: capitalize;
        text-align: center;
        font-size: 16px;
        width:316px;
        margin-top: 20px !important;
    }
    .hoverImg{
        width: 366px;
        height: 422px;
    }
    .flip {
        position: relative;
        >.front,
        >.back {
            display: block;
            /* transition-timing-function: cubic-bezier(.175, .885, .32, 1.275); */
            transition-duration: 2s;
            transition-property: transform, opacity;
        }
        >.front {
            transform: rotateX(0deg);
        }
        >.back {
            position: absolute;
            opacity: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: rotateX(-10deg);
        }
        &:hover {
            >.front {
                transform: rotateX(10deg);
            }
            >.back {
                opacity: 1;
                transform: rotateX(0deg);
            }
        }
    }
    
    .flip {
        position: relative;
        display: inline-block;
        >.back {
          display: block;
          color: white;
          width: inherit;
          background-size: cover!important;
          background-position: center!important;
          width: 303px;
          height: 391px;
          padding: 1em 2em;
          /* background: #313131; */
          border-radius: 5px;
          p {
            font-size: 0.9125rem;
            line-height: 160%;
            color: #999;
          }
        }
    }
    .left{
        border-radius: 0px 0px 0px 0px;
    }
    .right{
        border-radius: 0px 0px 0px 0px;
    }
}
/* Media query for mobile devices */
@media (max-width: 480px) {
    .mainHeadHover{
        width: 290px !important;
        text-align: center !important;
        font-size: 32px !important;
        line-height: 39.53px;
        text-transform: capitalize !important;
    }
    .mainHead1{
        text-transform: capitalize;
        text-align: center;
        font-size: 16px;
        width:316px;
        margin-top: 20px !important;
    }
    .hoverImg{
        width: 295px;
        height: 395px;
    }
    .flip {
        position: relative;
        >.front,
        >.back {
            display: block;
            /* transition-timing-function: cubic-bezier(.175, .885, .32, 1.275); */
            transition-duration: 2s;
            transition-property: transform, opacity;
        }
        >.front {
            transform: rotateX(0deg);
        }
        >.back {
            position: absolute;
            opacity: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: rotateX(-10deg);
        }
        &:hover {
            >.front {
                transform: rotateX(10deg);
            }
            >.back {
                opacity: 1;
                transform: rotateX(0deg);
            }
        }
    }
    
    .flip {
        position: relative;
        display: inline-block;
        >.back {
          display: block;
          color: white;
          width: inherit;
          background-size: cover!important;
          background-position: center!important;
          width :232px;
          height: 363px;
          padding: 1em 2em;
          /* background: #313131; */
          border-radius: 5px;
          p {
            font-size: 0.9125rem;
            line-height: 160%;
            color: #999;
          }
        }
    }
    .left{
        border-radius: 0px 0px 0px 0px;
    }
    .right{
        border-radius: 0px 0px 0px 0px;
    }
    .contentButton{
        font-size: 18px !important;
    }
    .buttonHoversection{
        padding: 20px 30px !important;
    }
}

  
  
  
  
  
