/* Hide the image on extra-small screens */
@media (max-width: 599px) {
    .my-image {
      display: none;
      width: 104px;
    }
    .my-image1 {
      display: flex;
      width: 104px;
    }
  }
  /* Display the image as flex on medium screens and above */
  @media (min-width: 600px) {
    .my-image {
      display: flex;
    }
    .my-image1 {
      display: none;
    }
  }
  /* Set margin-right to 1 unit (adjust as needed) */
  .my-image {
    margin-right: 1px;
    width: 200px;
    margin-top: -8px;
  }
  /* Set margin-right to 1 unit (adjust as needed) */
.my-image1 {
    margin-right: 1px;
  }
 