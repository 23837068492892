.footerContainer{
    background-color: #000;
    padding: 130px;
}
.gridDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footerfullLogo{
    margin-right: 1px;
    margin-top: -8px;
    width: 363px !important;
    height: 110px !important;
}
.footerFirstDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    padding-right: 30px;
}
.socialLogo{
    width: 50px !important;
    height: 50px !important;
    margin-right: 12px !important;
    border: 0px solid #F3F9FF;
}
.copyRight{
    font-family: 'Poppins' !important;
    text-transform: lowercase !important;
    text-align: center !important;
    font-size: 18px !important;
    color: #BFC8DF !important;
    margin-top: 30px  !important;
}
.TypographyMain{
    font-family: 'Poppins' !important;
    text-transform: lowercase !important;
    text-align: left !important;
    font-size: 22px !important;
    color:#BFC8DF !important;
    margin-top:20px !important; 
  }
  .Typography{
    font-family: 'Poppins' !important;
    text-align: left !important;
    font-size: 14px !important;
    color:#BFC8DF !important;
    margin-top:20px !important; 
  }
  .copyGrid{
    display: none;
  }


  @media (max-width: 640px) {
     .grid1{
        order:1;
    }
     .grid3{
        order:2;
    }
     .grid2{
        order:3
    }
     .grid4{
        order:4;
        margin-top: -32px !important;
    }
     .copyGrid{
        order: 5;
    }
     .footerContainer{
        padding: 90px;
    }
     .footerfullLogo{
        margin-right: 0px;
        margin-top: 0px;
    }
     .footerFirstDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 6px;
        padding-right: 49px;
    }
    .socialLogo{
        width: 40px;
        height: 40px;
       
    }
    .TypographyMain{
        font-family: 'Poppins' !important;
        text-transform: capitalize !important;
        text-align: left !important;
        font-size: 22px !important;
        color:#BFC8DF !important;
        margin-top:20px !important; 
    }
      .Typography{
        margin-top:4px !important; 
        text-transform: capitalize !important;
    }
    .copyGrid1{
        display: none;
    }
    .copyGrid{
        display: inline;
    }

  }
  @media (max-width: 480px){
    .footerfullLogo{
        margin-right: 0px;
        margin-top: 0px;
        margin-left: -63px !important;
    }
    .footerFirstDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 6px;
        padding-right: -16px !important;
    }
  }