.mainHeadContact{
    display: inline-block;
    width: 731px;
    text-align: left;
    font-size: 36px !important;
    line-height: 44.47px !important;
    text-transform: lowercase;
    font-weight: 700;
    font-family: 'Poppins';
}
.whiteContact{
    color:#F3F9FF;
}
.blueContact{
    color:#0485E1;
}
.mainHeadContactDesc{
    display: inline-block;
    width: 731px;
    text-align: left; 
    font-size: 20px;
    line-height: 24.7px;
    text-transform: lowercase;
    font-weight: 600;
    color:#F3F9FF;
    font-family: 'Poppins';
}
.contactForm{
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding:50px 0px 20px 20%;
}
.inputBox{
    display: block;
    padding: 9px;
    width: 300px;
    height: 30px;
    border: 1px solid #0485E1;
    background-color: #C6E2FD;
    border-radius: 5px;
}
.labelSection{
    font-family: 'Poppins';
    color:#F2F8FE;
    font-size: 20px;
    /* margin-left: -283px; */
}
.contactButton{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 5px;
  color:#F3F9FF;
  border: 1px solid #F3F9FF;
  margin-left:-40px;
}
.contactSetInput{
    margin:40px 0px 0px 0px !important;
}
.contactDiv{
    align-items: center;
    padding: 40px;
}
@media (max-width: 640px) {
    .mainHeadContact{
        width: 302px !important;
        text-align: left !important;
        font-size: 35px !important;
        line-height: 39.53px !important;
    }
    .capitalContact{
        text-transform: uppercase;
    }
    .mainHeadContactDesc{
        width: 302px !important;
        font-size: 18px !important;
        line-height: 21.7px !important;
    }
    .contactForm{
        padding:12px 0px 20px 81px !important;
    }
    .contactSetInput{
        margin:0px;
    }
    .contactButton{
        margin: 0px 0px 0px 62px;
    }
    .contactMargin{
        margin: 20px 0px 0px 0px !important;
    }
}
/* Media query for mobile devices */
@media (max-width: 480px) {
    .mainHeadContact{
        width: 268px !important;
        text-align: left !important;
        font-size: 32px !important;
        line-height: 35.53px !important;
    }
    .capitalContact{
        text-transform: uppercase;
    }
    .mainHeadContactDesc{
        width: 265px !important;
        font-size: 16px !important;
        line-height: 19.7px;
    }
    .contactForm{
        padding:0px 0px 0px 6px !important;
    }
    .contactSetInput{
        margin:0px;
    }
    .contactButton{
        margin: 0px 0px 0px 62px;
    }
    .contactMargin{
        margin: 20px 0px 0px 0px !important;
    }
    
    
}

/* Media query for mobile devices */
@media (max-width: 320px) {
    .mainHeadContact{
        width: 219px !important;
        text-align: left !important;
        font-size: 24px !important;
        line-height: 28.53px !important;
    }
    .capitalContact{
        text-transform: uppercase;
    }
    .mainHeadContactDesc{
        width: 220px !important;
        font-size: 14px !important;
        line-height: 15.7px !important;
    }
    .contactForm{
        padding:0px 0px 0px 15px !important;
    }
    .contactSetInput{
        margin:0px;
    }
    .contactButton{
        padding: 6px 18px !important;
        margin: -10px 0px 0px 16px;
    }
    .contactMargin{
        
        margin: 20px 0px 0px 0px !important;
    }
    .inputBox{
        padding: 0px !important;
        width: 219px !important;
        height: 34px !important;
    }
    .labelSection{
        font-size: 14px;
    }
    
}