body{
    background-color: #000;
}
.fullLogo{
  margin-right: 1px;
  width: 128px;
  height: 122.37px;
  margin-top: 25px;
}
.whiteContent{
    color:#F3F9FF;
}
.blueContent{
    color:#0485E1;
}
.topSectionDiv{
  text-align: center;
  height: 85vh;
}
.mainHeadTop{
  display: inline-block;
  width: 800px !important;
  text-align: center !important;
  font-size: 45px !important;
  line-height: 117%;
  text-transform: lowercase;
  font-weight: 600;
  font-family: 'Poppins';
}
.mainHead1{
    font-family: 'Poppins';
    text-transform: lowercase;
    text-align: center;
    font-size: 18px;
    color:#BFC8DF;
    margin-top:30px;
    width:650px;
}
.backgroundvideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    bottom: 0;
    z-index: -1;
}
@media (max-width: 768px) {
   .mainHeadTop{
      width: 334px !important;
      line-height: 42.12px !important;
    }
    .mainHead1{
        width: 580px;
    }
    .fullLogo{
      width: 88px;
      height: 87px;
    }
  }
  
/* Media query for mobile devices */
@media (max-width: 480px) {
   .mainHeadTop{
    font-size: 40px !important;
    width: 341px !important;
    line-height: 42.12px !important;
   }
   .mainHead1{
     width: 283px !important;
     line-height: 22.23px !important;
     font-size: 20px !important;
   }
   .backgroundvideo {
    width: 100%;
    height: 208vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 251px;
    bottom: 0;
    z-index: -1;
   }
   .topSectionDiv{
    height: 156vh;
   }
}

  
  
  
  
  
