

.bannerInner {
    left: calc(50% - 656px);
    border-radius: 34px;
    background: linear-gradient(180deg, #c6e2fd, #fbfdfe);
    width: 100%;
    height: 680px;
    overflow: hidden;

  }
  .banner {
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    background-color: var(--color-white);
    text-align: center;
  }

  .groupParent{
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .cardContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 20px;
  }
  
.getThatWorkContainer {
    line-height: 123.52%;
    text-transform: lowercase;
    width: 100%;
    margin-bottom: 30px;
}

.getThatWorkContainer>span{
    font-size: 36px;
    color: #020d1f;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 123.45%;
}

.getThatWorkContainer>.talentedFolks{
    color: #0485e1;
}
  
  .talentedFolks {
    color: var(--color-cornflowerblue);
    }
  
  .getThatWorkDoneBrilliantlParent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .accessBrightMindedTalented {
    font-size: var(--font-size-xl);
    line-height: 120.02%;
    font-weight: 600;
    display: inline-block;
    width: 70%;
    font-size: 20px;
    color: #020d1f;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    }
  
  .contentTitle {
    line-height: 120.02%;
    text-transform: lowercase;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #020d1f;
  }
  
  .groupContainer {
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--b6478);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .folksCapableOfFlexingWithParent{
    width: 385px;
  }

  .folksCapableOf{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #5b6478;
  }

  .flexForceBtn{
    background-color: #221F4B;
    font-size: 24px;
    color: #F3F9FF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
  }
  
  .dots {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  
  .dots li button {
    font-size: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .dots li button::before {
    font-size: 26px;
    content: '•';
    color: #221F4B;
  }
  
  .dots li.active button::before {
    border: 1px solid #221F4B;
    border-radius: 50%;
  }

.videoSection{
    margin: 50px 0px;
}

  .contentVideo {
    width: 100%;
    height: 300px;
    object-fit: cover;
    z-index: 1;
}
.bannerMobile{
  display: none;
}

@media (max-width: 768px) {
  .banner{
    display: none;
  }
  .bannerMobile{
    display: inline;
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    background-color: var(--color-white);
    text-align: center;
  }
  .bannerInner {
    left: calc(50% - 656px);
    border-radius: 34px;
    background: linear-gradient(180deg, #c6e2fd, #fbfdfe);
    width: 100%;
    height: auto !important;
    overflow: hidden;

  }
  .getThatWorkContainer {
    line-height: 123.52%;
    text-transform: lowercase;
    width: 100%;
    margin-bottom: 30px;
    width: 301px;
    font-size: 32px;
    margin-top: 33px;
  }
  .accessBrightMindedTalented{
    width: 325px !important;
  }
  .flexForceBtnContainer{
    margin-bottom: 25px !important;
  }
  .groupContainer{
    text-align: center;
    align-items: center;
  }
  .contentVideo{
    border-radius: 5px !important;
  }
}
@media (max-width: 640px) {
  .banner{
    display: none;
  }
  .bannerMobile{
    display: inline;
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    background-color: var(--color-white);
    text-align: center;
  }
  .bannerInner {
    left: calc(50% - 656px);
    border-radius: 34px;
    background: linear-gradient(180deg, #c6e2fd, #fbfdfe);
    width: 80%;
    height: auto !important;
    overflow: hidden;

  }
  .getThatWorkContainer {
    line-height: 123.52%;
    text-transform: lowercase;
    width: 100%;
    margin-bottom: 30px;
    width: 301px;
    font-size: 32px;
    margin-top: 33px;
  }
  .accessBrightMindedTalented{
    width: 325px !important;
  }
  .flexForceBtnContainer{
    margin-bottom: 25px !important;
  }
  .groupContainer {
    text-align: center;
    align-items: center;
  }
  .contentVideo{
    border-radius: 5px !important;
  }
}

@media (max-width: 480px){
  .folksCapableOfFlexingWithParent{
    width: 287px !important;
  }
  .banner{
    display: none;
  }
  .bannerMobile{
    display: inline;
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    background-color: var(--color-white);
    text-align: center;
  }
  .bannerInner {
    left: calc(50% - 656px);
    border-radius: 34px;
    background: linear-gradient(180deg, #c6e2fd, #fbfdfe);
    width: 80%;
    height: auto !important;
    overflow: hidden;

  }
  .getThatWorkContainer {
    line-height: 123.52%;
    text-transform: lowercase;
    width: 100%;
    margin-bottom: 30px;
    width: 301px;
    font-size: 32px;
    margin-top: 33px;
  }
  .accessBrightMindedTalented{
    width: 325px !important;
  }
  .flexForceBtnContainer{
    margin-bottom: 25px !important;
  }
  .groupContainer {
    text-align: center;
    align-items: center;
  }
  .contentVideo{
    border-radius: 5px !important;
  }
}